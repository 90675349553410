import React from "react";
import {
  BannerSection,
  BottomSeciton,
  MiddleSeciton,
  FooterSection,
} from "../components";

export default function Home() {
  return (
    <>
      <BannerSection />
      <MiddleSeciton />
      <BottomSeciton />
      <FooterSection />
    </>
  );
}
